<template>
    <div class="box_content">
        <div class="divisor_bottom px-2 px-lg-4">
            <div class="row pt-3 pb-2">
                <div class="input_copy col-12">
                    <BaseInput 
                        class="col-12"
                        label="URL da campanha *"
                        type="text"
                        id="url"
                        :required="false"
                        v-model="campaign.url"
                        :page="route.name"
                        disabled
                    />
                    <button class="btn copy" @click="copy">Copiar código</button>
                    <button class="btn copied" @click="copy">Copiado</button>
                </div>
            </div>
        </div>
        <div class="px-2 px-lg-4 py-4">
            <form @submit.prevent="submit">
                    <div class="row">
                    <BaseInput 
                        class="col-md-6 col-12"
                        id="title"
                        label="Título *"
                        type="text"
                        :required="true"
                        v-model="campaign.title"
                        :page="route.name"
                    />
                    <BaseSelect
                        class="col-md-6 col-12"
                        id="id_destination"
                        :items="destinationItems"
                        label="Destino *"
                        v-model="campaign.destination"
                        :required="true"
                        :page="route.name"
                        :multiList=true
                        @emitListType="setDestinationType"
                    />
                    <BaseInput
                        class="col-12"
                        id="utm_source"
                        label="UTM Source"
                        type="text"
                        :required="false"
                        v-model="campaign.utm_source"
                        :page="route.name"
                    />
                    <BaseInput 
                        class="col-12"
                        id="utm_medium"
                        label="UTM Medium"
                        type="text"
                        :required="false"
                        v-model="campaign.utm_medium"
                        :page="route.name"
                    />
                    <BaseInput 
                        class="col-12"
                        id="utm_campaign"
                        label="UTM Campaign"
                        type="text"
                        :required="false"
                        v-model="campaign.utm_campaign"
                        :page="route.name"
                    />
                    <BaseInput 
                        class="col-12"
                        id="utm_term"
                        label="UTM Term"
                        type="text"
                        :required="false"
                        v-model="campaign.utm_term"
                        :page="route.name"
                    />
                    <BaseInput 
                        class="col-12"
                        id="utm_content"
                        label="UTM Content"
                        type="text"
                        :required="false"
                        v-model="campaign.utm_content"
                        :page="route.name"
                    />
                    <BaseInput 
                        class="col-12"
                        id="src"
                        label="SRC"
                        type="text"
                        :required="false"
                        v-model="campaign.src"
                        :page="route.name"
                    />
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount ,computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { useCampaignStore } from '@/stores/campaign';

const route = useRoute();
const router = useRouter();

const campaign_id = computed (function () {
    return route.params.id;
});

const previousPage = ref(null)
const campaignStore = useCampaignStore();
const { campaign } = storeToRefs(campaignStore);

const destinationItems = ref({
    pages: {},
    products: {},
})

onBeforeMount(() => {
    previousPage.value = router.options.history.state.back;
    if(campaignStore.campaign.id){
        campaignStore.tryDestination(campaignStore.campaign.id_store).then(() =>{
            destinationItems.value.pages = campaignStore.destination_pages
            destinationItems.value.products = campaignStore.destination_products
                setTimeout(() =>{
                    loadSelect();
            },50)      
        })
        destinationItems.value.pages = campaignStore.destination_pages
        destinationItems.value.products = campaignStore.destination_products
    } else{
        campaignStore.tryCampaign(campaign_id.value).then(() =>{
            campaignStore.tryDestination(campaignStore.campaign.id_store).then(() =>{
                destinationItems.value.pages = campaignStore.destination_pages
                destinationItems.value.products = campaignStore.destination_products
                setTimeout(() =>{
                    loadSelect();
                },50)            
            })
        })
    }
});

function loadSelect(){
        if(campaignStore.campaign.type_destination == 'page'){
            for(let index = 0; index < campaignStore.destination_pages.length; index++){
                if(campaignStore.destination_pages[index].id === campaignStore.campaign.id_destination){
                    document.querySelector('input#id_destination').value = campaignStore.destination_pages[index].title
                }
            }
        } else{
            for(let index = 0; index < campaignStore.destination_products.length; index++){
                if(campaignStore.destination_products[index].id === campaignStore.campaign.id_destination){
                    document.querySelector('input#id_destination').value = campaignStore.destination_products[index].title
            }              
        }
    }
}

function setDestinationType({typeValue, itemId}){
    campaignStore.campaign.type_destination = typeValue;
    campaignStore.campaign.id_destination = itemId;
}

function copy(){
    const getCopyButton = document.querySelector('.input_copy .copy')
    const getCopiedButton = document.querySelector('.input_copy .copied')
    getCopyButton.style.display = 'none';
    getCopiedButton.style.display = 'block'
    var copyText = campaignStore.campaign.url;
    navigator.clipboard.writeText(copyText);
}
</script>

<style scoped>
.input_copy{
    padding: 4px 10px;
    border-radius: 40px;
    position: relative;
}

.input_copy input{
    border: 0;
    width: 100%;
    font-size: 14px;
    background-color: white;
}

.input_copy input:active, .input_copy input:focus{
    border: 0px!important;
}

.input_copy button{
    color: #fff !important;
    position: absolute;
    top: 38px;
    right: 15px;
    padding: 5px 15px 5px 15px;
    border-radius: 25px;
    font-size: 14px;
    width: 132px;
    text-align: center;
}

.input_copy .copy {
    background-color: #000;
}

.input_copy .copy:hover{
    background-color: #313131;
}

.input_copy .copied {
    background-color: #4caf50;
    display: none;
}
</style>