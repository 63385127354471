<template>
    <form @submit.prevent="submit">
        <BaseInputCode
            class="mb-2"
            id="code"
            v-model="login.code"
            :required="true"
            :maxlength="8"
            :page="route.name"
        />

        <BaseButton 
            class="mt-4 btn-primary w-100"
            :loading="isLoading"
            :disabled="isLoading">
            Continuar
        </BaseButton>
    </form>
</template>
<script setup>
import { ref, onMounted, onBeforeMount } from "vue";

import { useGlobalStore } from '@/stores/global';
import { useAuthStore } from '@/stores/auth';
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from 'pinia';

const authStore = useAuthStore();
const global = useGlobalStore();
const route = useRoute();
const router = useRouter();

const { login } = storeToRefs(authStore);

const isLoading = ref(false);

onBeforeMount(() => {
    if(!authStore.login.email){
        router.push('/login');
    }
});

async function submit() {
    isLoading.value = false;

    // eslint-disable-next-line
    grecaptcha.ready(() => {
        isLoading.value = true;
        // eslint-disable-next-line
        grecaptcha.execute(window.RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {
            authStore.login.recaptcha_token = token;

            authStore.tryLogin(route.name).then(function(success) {
                isLoading.value = false;

                useGlobalStore().cleanObject(authStore.login);

                if(success == false){
                    router.push('/login');
                }
            });
        });
    });
}

onMounted(() => {
    delete global.errors[route.name];
});
</script>

<style scoped>
.form-check-label {
    vertical-align: middle;
    font-size: 13px;
    display: flex;
}

.form-check-input {
    width: 100%;
    height: 20px;
    max-width: 20px;
    margin-right: 10px;
}
</style>