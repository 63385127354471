<template>
    <div class="box p-3 pb-2 mb-3">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-sm-6 col-12">
                        <p class="mb-3">
                            <strong class="d-block">Loja</strong>
                            <BaseStoreDot :color="props.store.color" :title="props.store.title" />
                        </p>
                    </div>
                    <div class="col-sm-6 col-12">
                        <p class="mb-3">
                            <strong class="d-block">Data</strong>
                            {{ globalStore.formatDate(props.payment.date) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <p class="mb-3">
                            <strong class="d-block">Pagamento</strong>
                            {{ props.payment.payment }}
                            <span v-if="props.payment.payment_method == 'credit-card'">{{ props.payment.credit_card_installments }}x</span>
                        </p>
                    </div>
                    <div v-if="props.payment.payment_method == 'boleto'" class="col-6">
                        <p class="mb-3">
                            <strong class="d-block">Vencimento</strong>
                            {{ globalStore.formatDateSimple(props.payment.expiration_date) }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    payment: {
        required: true,
        default: null
    },

    store: {
        required: true,
        default: null
    },
});

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
</script>