<template>
    <div class="main_nav_div scrollbar">
        <ul id="main_nav" class="main_nav nav-pills">

            <li :class="'nav_item ' + [route.meta.menuPage == 'home' ? 'active' : '']">
                <router-link @click="checkActivePage('/')" to="/">
                    <font-awesome-icon :icon="[route.meta.menuPage == 'home' ? 'fafar' : 'fa-regular'] + ' fa-house'" />
                    <span>Home</span>
                </router-link>
            </li>

            <li v-if="globalStore.hasPermissionTo('orders')" :class="'nav_item ' + [route.meta.menuPage == 'orders' ? 'active' : '']">
                <router-link @click="checkActivePage('/orders')" to="/orders">
                    <font-awesome-icon :icon="[route.meta.menuPage == 'orders' ? 'fafar' : 'fa-regular'] + ' fa-cart-shopping'" />
                    <span>Pedidos</span>
                </router-link>
            </li>

            <li v-if="globalStore.hasPermissionTo('stores')" :class="'nav_item ' + [route.meta.menuPage == 'stores' ? 'active' : '']">
                <router-link @click="checkActivePage('/stores')" to="/stores">
                    <font-awesome-icon :icon="[route.meta.menuPage == 'stores' ? 'fafar' : 'fa-regular'] + ' fa-store'" />
                    <span>Lojas</span>
                </router-link>
            </li>

            <li v-if="globalStore.hasPermissionTo('products')" :class="'nav_item ' + [route.meta.menuPage == 'products' ? 'active' : '']">
                <router-link @click="checkActivePage('/products')" to="/products">
                    <font-awesome-icon :icon="[route.meta.menuPage == 'products' ? 'fafar' : 'fa-regular'] + ' fa-box'" />
                    <span>Produtos</span>
                </router-link>
            </li>

            <li v-if="globalStore.hasPermissionTo('refunds')" :class="'nav_item ' + [route.meta.menuPage == 'refunds' ? 'active' : '']">
                <router-link @click="checkActivePage('/refunds')" to="/refunds">
                    <font-awesome-icon :icon="[route.meta.menuPage == 'refunds' ? 'fafar' : 'fa-regular'] + ' fa-badge-dollar'" />
                    <span>Reembolsos</span>
                </router-link>
            </li>

            <li v-if="globalStore.hasPermissionTo('campaigns')" :class="'nav_item ' + [route.meta.menuPage == 'campaigns' ? 'active' : '']">
                <router-link @click="checkActivePage('/campaigns')" to="/campaigns">
                    <font-awesome-icon :icon="[route.meta.menuPage == 'campaigns' ? 'fafar' : 'fa-regular'] + ' fa-bullhorn'" />
                    <span>Campanhas</span>
                </router-link>
            </li>

            <li v-if="globalStore.hasPermissionTo('wallet')" :class="'nav_item ' + [route.meta.menuPage == 'withdrawals' ? 'active' : '']">
                <router-link @click="checkActivePage('/withdrawals')" to="/withdrawals">
                    <font-awesome-icon :icon="[route.meta.menuPage == 'withdrawals' ? 'fafar' : 'fa-regular'] + ' fa-wallet'" />
                    <span>Carteira</span>
                </router-link>
            </li>

            <li v-if="globalStore.hasPermissionTo('support')" :class="'nav_item ' + [route.meta.menuPage == 'support' ? 'active' : '']">
                <router-link @click="checkActivePage('/support')" to="/support">
                    <font-awesome-icon :icon="[route.meta.menuPage == 'support' ? 'fafar' : 'fa-regular'] + ' fa-comment-lines'" />
                    <span>Suporte</span>
                </router-link>
            </li>


            <li v-if="globalStore.hasPermissionTo('abandoned_carts') || globalStore.hasPermissionTo('orders_refused')" class="nav_item dropdown-toggle" :data-bs-toggle="[auth.menu_colappsed == 1 ? 'dropdown' : 'collapse']" data-bs-target="#collapse_recovery">
                <a title="Recuperação"
                :class="[route.meta.menuPage == 'abandoned-carts' || route.meta.menuPage == 'orders-refused' ? 'active' : '']">
                    <font-awesome-icon
                        :class="[route.meta.menuPage == 'abandoned-carts' || route.meta.menuPage == 'orders-refused' ? 'active' : '']"
                        :icon="[route.meta.menuPage == 'abandoned-carts' || route.meta.menuPage == 'orders-refused' ? 'fafar' : 'fa-regular'] + ' fa-clock-rotate-left'"
                    />

                    <span>Recuperação</span>
                    <font-awesome-icon
                        :class="'angle_down_arrow ' + [route.meta.menuPage == 'abandoned-carts' || route.meta.menuPage == 'orders-refused' ? 'active' : '']"
                        icon="fafar fa-angle-down"
                    />
                </a>

            </li>

            <div :class="'accordion-collapse collapse ' + [auth.menu_colappsed == 1 ? 'dropdown-menu' : 'collapse-menu'] + ' ' + [route.meta.menuPage == 'abandoned-carts' || route.meta.menuPage == 'orders-refused' ? 'show' : '']" id="collapse_recovery">
                <div class="accordion-body">
                        <li v-if="globalStore.hasPermissionTo('abandoned_carts')" :class="'nav_item ' + [route.meta.menuPage == 'abandoned-carts' ? 'active' : '']">
                            <router-link @click="checkActivePage('/recovery/abandoned-carts')" to="/recovery/abandoned-carts">
                                <span>Checkouts abandonados</span>
                            </router-link>
                        </li>
                        <li v-if="globalStore.hasPermissionTo('orders_refused')" :class="'nav_item ' + [route.meta.menuPage == 'orders-refused' ? 'active' : '']">
                            <router-link @click="checkActivePage('/recovery/orders-refused')" to="/recovery/orders-refused">
                                <span>Pedidos recusados</span>
                            </router-link>
                        </li>
                </div>
            </div>

            <li v-if="globalStore.hasPermissionTo('coproductors')" :class="'nav_item ' + [route.meta.menuPage == 'coproductors' ? 'active' : '']" title="Coprodutores">
                <router-link @click="checkActivePage('/coproductors')" to="/coproductors">
                    <font-awesome-icon :icon="[route.meta.menuPage == 'coproductors' ? 'fafar' : 'fa-regular'] + ' fa-user-vneck'" />
                    <span>Coprodutores</span>
                </router-link>
            </li>

            <li class="nav_item dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#collapseReports" role="button">

                <a :class="[route.meta.menuPage == 'reports-referrals' || route.meta.menuPage == 'reports-productors' || route.meta.menuPage == 'reports-products' || route.meta.MenuPage == 'reports-recommendations' || route.meta.menuPage == 'reports-payment-methods' || route.meta.menuPage == 'reports-conversions' || route.meta.menuPage == 'reports-insights' ? 'active' : '']">
                    <font-awesome-icon
                        :class="[route.meta.menuPage == 'reports-referrals' || route.meta.menuPage == 'reports-productors' || route.meta.menuPage == 'reports-products' || route.meta.menuPage == 'conversions' || route.meta.MenuPage == 'reports-recommendations' || route.meta.menuPage == 'reports-payment-methods' || route.meta.menuPage == 'reports-conversions' || route.meta.menuPage == 'tests-ab' || route.meta.menuPage == 'reports-insights'? 'active' : '']"
                        :icon="[route.meta.menuPage == 'reports-referrals' || route.meta.menuPage == 'reports-productors' || route.meta.menuPage == 'reports-products' || route.meta.menuPage == 'conversions' || route.meta.MenuPage == 'reports-recommendations' || route.meta.menuPage == 'reports-payment-methods' || route.meta.menuPage == 'reports-conversions' || route.meta.menuPage == 'tests-ab' || route.meta.menuPage == 'reports-insights' ? 'fafar' : 'fa-regular'] + ' fa-chart-simple'"
                    />
                    <span>Relatórios</span>
                    <font-awesome-icon
                        :class="'angle_down_arrow ' + [route.meta.menuPage == 'reports-referrals' || route.meta.menuPage == 'reports-productors'|| route.meta.menuPage == 'reports-products' || route.meta.menuPage == 'reports-payment-methods' || route.meta.menuPage == 'reports-conversions' || route.meta.MenuPage == 'reports-recommendations' || route.meta.menuPage == 'reports-insights' ? 'active' : '']"
                        icon="fafar fa-angle-down"
                    />
                </a>
            </li>
            <div v-if="globalStore.hasPermissionTo('report_referrals') || globalStore.hasPermissionTo('report_productors') || globalStore.hasPermissionTo('report_products') || globalStore.hasPermissionTo('report_payment_methods') || globalStore.hasPermissionTo('report_conversions')" :class="'accordion-collapse collapse ' + [route.meta.menuPage == 'reports-referrals' || route.meta.menuPage == 'reports-productors' || route.meta.menuPage == 'reports-products' || route.meta.menuPage == 'reports-payment-methods' || route.meta.menuPage == 'reports-conversions'  || route.meta.menuPage == 'reports-recommendations' || route.meta.menuPage == 'tests-ab' || route.meta.menuPage == 'reports-insights' ? 'show' : '']" id="collapseReports">
            <div class="accordion-body">
                        <li v-if="globalStore.hasPermissionTo('report_insights')" :class="'nav_item ' + [route.meta.menuPage == 'reports-insights' ? 'active' : '']">
                            <router-link @click="checkActivePage('/reports/insights')" to="/reports/insights">
                                <span>Insights</span>
                            </router-link>
                        </li>
                        <li v-if="globalStore.hasPermissionTo('report_referrals')" :class="'nav_item ' + [route.meta.menuPage == 'reports-referrals' ? 'active' : '']">
                            <router-link @click="checkActivePage('/reports/referrals')" to="/reports/referrals">
                                <span>Meus afiliados</span>
                            </router-link>
                        </li>

                        <li v-if="globalStore.hasPermissionTo('report_productors')" :class="'nav_item ' + [route.meta.menuPage == 'reports-productors' ? 'active' : '']">
                            <router-link @click="checkActivePage('/reports/productors')" to="/reports/productors">
                                <span>Meus produtores</span>
                            </router-link>
                        </li>

                        <li v-if="globalStore.hasPermissionTo('report_products')" :class="'nav_item ' + [route.meta.menuPage == 'reports-products' ? 'active' : '']">
                            <router-link @click="checkActivePage('/reports/products')" to="/reports/products">
                                <span>Produtos vendidos</span>
                            </router-link>
                        </li>

                        <li v-if="globalStore.hasPermissionTo('report_payment_methods')" :class="'nav_item ' + [route.meta.menuPage == 'reports-payment-methods' ? 'active' : '']">
                            <router-link @click="checkActivePage('/reports/payment-methods')" to="/reports/payment-methods">
                                <span>Métodos de pagamento</span>
                            </router-link>
                        </li>

                        <li v-if="globalStore.hasPermissionTo('report_conversions')" :class="'nav_item ' + [route.meta.menuPage == 'reports-conversions' ? 'active' : '']">
                            <router-link @click="checkActivePage('/reports/conversions')" to="/reports/conversions">
                                <span>Conversão</span>
                            </router-link>
                        </li>
                        
                        <li v-if="globalStore.hasPermissionTo('report_recommendations')" :class="'nav_item ' + [route.meta.menuPage == 'reports-recommendations' ? 'active' : '']">
                            <router-link @click="checkActivePage('/reports/recommendations')" to="/reports/recommendations">
                                <span>Minhas indicações</span>
                            </router-link>
                        </li>
                        <li v-if="globalStore.hasPermissionTo('report_tests_ab')" :class="'nav_item ' + [route.meta.menuPage == 'tests-ab' ? 'active' : '']">
                            <router-link @click="checkActivePage('/reports/tests-ab')" to="/reports/tests-ab">
                                <span>Testes A/B</span>
                            </router-link>
                        </li>
                </div>
            </div>

            <li v-if="globalStore.hasPermissionTo('users')" :class="'nav_item ' + [route.meta.menuPage == 'users' ? 'active' : '']">
                <router-link @click="checkActivePage('/users')" to="/users">
                    <font-awesome-icon :icon="[route.meta.menuPage == 'users' ? 'fafar' : 'fa-regular'] + ' fa-user-tie'" />
                    <span>Usuários</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount } from "vue";
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth';

const route = useRoute();
const auth = useAuthStore();

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const emit = defineEmits(['reloadSameRoute'])

onMounted(() => {
    let clickedOnMenu = document.querySelectorAll("#main_nav li:not(.dropdown-toggle)");
    for (let i = 0; i < clickedOnMenu.length; i++) {
        clickedOnMenu[i].addEventListener('click', closeMenu, true);
    }
});


function checkActivePage(route_to){
    if(route_to === route.path){
        emit('reloadSameRoute')
    }
}

onBeforeUnmount(() => {
    window.removeEventListener('click', closeMenu, true);
});

function closeMenu() {
    document.getElementById('navbar').style.height = '70px';
    document.getElementById('footer_nav').style.display = 'none';
}

</script>

<style>
.main_nav_div {
    height: calc(100vh - 144px) !important;
    overflow: scroll;
    padding-bottom: 0px;
    overflow-x: hidden;
}

#app.menu-collapsed .main_nav_div {
    height: calc(100vh - 140px) !important;
}

.main_nav {
    padding: 10px 10px 0px 15px;
    list-style: none;
}

li {
    list-style: none;
}

li a, .nav_item a, ul a {
    text-decoration: none !important;
    color: #595959 !important;
}

.nav_item {
    border-radius: 5px;
    margin-bottom: 5px;
}

.nav_item a {
    color: #595959;
    font-size: 13px;
    padding: 14px 5px 14px 9px;
    font-weight: 500;
    cursor: pointer;
    display: block;
    position: relative;
}

.nav_item svg:first-of-type {
    fill: #595959;
    margin-right: 12px;
    width: 20px;
    height: 18px;
    vertical-align: middle;
}

.accordion-body .nav_item span:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 4px;
    background: #215eda;
    border-radius: 25px;
    top: -2px;
    left: 5px;
    position: relative;
    margin-right: 24px;
}

/*remove animação de slidedown do collapse */
.collapsing {
    -webkit-transition: none !important;
    transition: none !important;
    display: none;
}

.nav_item:hover,
.nav_item:hover {
    background-color: #ededed !important;
    color: rgb(81, 81, 81);
}

.nav_item svg.active,
.nav_item.active a,
.nav_item a.active {
    color: #215eda !important;
    fill: #215eda !important;
    background-color: transparent;
}

.nav_item.active a,
.active,
svg .active {
    fill: #215eda;
}

#main_nav .dropdown-toggle::after {
    display: none !important;
}

.scrollbar {
    overflow-y: scroll;
    scrollbar-color: #ccc rgb(0 0 0 / 0%);
    scrollbar-width: thin !important;
}

.angle_down_arrow {
    float: right;
    width: 12px !important;
    height: 22px !important;
    fill: #595959;
    margin-right: 9px;
    position: absolute;
    right: 20px;
    transition: 0.5s;
}

#app.menu-collapsed #navbar .nav_item span {
    font-size: 0;
}

#app.menu-collapsed #navbar .dropdown-menu .nav_item span {
    display: block;
    white-space: nowrap;
}

#app.menu-collapsed #navbar .dropdown-menu .nav_item span:before {
    display: none;
}

#app.menu-collapsed #navbar .dropdown-menu .nav_item {
    border-radius: 0;
    margin-bottom: 0;
}

#app.menu-collapsed #navbar .dropdown-menu .nav_item a {
    padding: 10px 20px;
}

#app.menu-collapsed #navbar .nav_item .angle_down_arrow {
    opacity: 0;
}

#app #navbar .collapse-menu {
    transform: inherit !important;
    position: relative !important;
}

@media screen and (max-width: 992px) {
    .main_nav_div {
        max-height: 85%;
    }

    .main_nav {
        height: 650px;
    }
}
</style>