<template>
    <div class="navbar_top">
        <div @click="toggleNavElements()" id="displayNavLowScreen">
            <font-awesome-icon class="bars_header_nav" icon="fa-regular fa-bars" />
        </div>
        <router-link class="link_logo" to="/" @click="closeMenu">
            <div class="logo_box">
                <TheLogo id="logo" />
            </div>
            <TheLogoIcon id="logo_icon" />
        </router-link>
    </div>
</template>

<script setup>
function toggleNavElements() {
    const getNavbarStyle = document.getElementById("navbar");
    const getNavFooter = document.getElementById("footer_nav");

    if (getNavbarStyle.style.height != "100%") {
        getNavbarStyle.style.height = "100%";
        getNavFooter.style.display = "block";

    } else {
        getNavbarStyle.style.height = "65px";
        getNavFooter.style.display = "none";
    }
}

function closeMenu() {
    document.getElementById('navbar').style.height = '70px';
    document.getElementById('footer_nav').style.display = 'none';
}
</script>

<style scoped>
.navbar_top {
    padding: 20px 15px 20px 25px;
}

.logo_box {
    margin-top: 0;
    margin-right: 0;
    width: 100%;
    outline: none;
    transition: 0.2s;
}

#app.menu-collapsed .logo_box {
    width: 23px;
    overflow: hidden;
    display: block;
    transition: 0.5s;
    opacity: 0;
}

#logo {
    width: 120px;
}

#logo_icon {
    position: absolute;
    top: 28px;
    left: 25px;
    width: 21px;
}


#displayNavLowScreen {
    display: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-right: 18px;
}

#displayNavLowScreen svg {
    width: 17px;
    height: 20px;
    fill: #595959;
    position: relative;
    top: 3px;
}

#toggle_menu {
    border: none;
    background-color: transparent;
    overflow: visible;
    width: 40px;
    height: 40px;
    padding: 10px;
    margin-top: -4px;
}

#app.menu-collapsed #toggle_menu {
    margin-left: 40px;
    margin-top: -10px;
}

.dropdown-item {
    font-size: 14px;
    cursor: pointer;
    color: #595959 !important;
    padding: 15px 15px;
    height: 38px;
    border: none;
    width: 100%;
    overflow: visible;
    text-decoration: none !important;
}

.dropdown-item:hover {
    background-color: #f9f9f9 !important;
}

#toggle_menu svg {
    width: 17px;
    height: 23px;
    cursor: pointer;
    color: #595959 !important;
    vertical-align: bottom;
}

#toggle_menu svg:hover {
    color: #414951 !important;
}

#notification_svg:hover {
    fill: black;
}

@media screen and (min-width: 992px) {
    #notification_svg {
        display: none !important;
    }
}

@media screen and (max-width: 992px) {
    #displayNavLowScreen {
        display: inline-block;
    }

    .navbar_top{
        display: flex;
    }

    #logo_icon{
        display: none;
    }

    .logo_box{
        opacity: 1!important;
        width: 100%!important;
    }

    .link_logo{
        margin-top: -2px;
        display: inline-block;
    }

    #bars_svg {
        display: none;
    }

    #notification_svg {
        display: none;
        margin-top: -6px;
        margin-left: 10%;
    }
}
</style>